<template>
  <!-- 单桥首页的数据查看 -->
  <div class="dataShow box box_column winStyle" v-if="closeShow == 1" :style="{ left: left + 'px', top: top + 'px' }">
    <!-- <div class="close pointer" >
    </div> -->
    <div class="top box justify center noFlex datatitle" @mousedown="startDrag">
      <div>数据查看</div>
      <div class="right-action">
        <img @click="large()" src="../../assets/images/alone/max.png" alt="" />
        <img @click="close" src="../../assets/images/all/guanbi_icon.png" alt="" />
      </div>
    </div>
    <div class="flex1 bottom box box_column noFlex">
      <div class="box justify mT10 mB10">
        <div class="new_ui_select">
          <!-- multiple collapse-tags -->
          <el-select v-model="value" value-key="id" placeholder="请选择" @change="changeTarget">
            <el-option-group v-for="group in options1" :key="group.label" :label="group.typeName">
              <el-option v-for="item in group.list" :key="item.id" :label="item.code" :value="item"> </el-option>
            </el-option-group>
          </el-select>
        </div>
        <div class="box center">
          <div style="margin-right: 16px" class="tab_new pointer" :class="tabActive.includes('3') == true ? 'tabColor' : ''" @click="tab('3')">实时数据</div>
          <div style="margin-right: 16px" class="tab_new pointer" :class="tabActive.includes('1') == true ? 'tabColor' : ''" @click="tab('1')">数据列表</div>
          <div class="tab_new pointer" :class="tabActive.includes('2') == true ? 'tabColor' : ''" @click="tab('2')">数据导出</div>
        </div>
      </div>
      <div class="newUi box justify" style="margin-bottom: 15px">
        <div class="box">
          <ul class="box center">
            <li style="margin-right: 8px" class="dayColorBg pointer" v-for="(item, index) in dayList" :key="index" :class="dayActive == item.id ? 'dayColor' : ''" @click="changeDay(item.id)">
              {{ item.name }}
            </li>
          </ul>
          <div class="timeUi" style="margin-right: 8px">
            <div class="icon1 pointer" @click="add">
              <img src="../../assets/images/alone/left.png" alt="" />
            </div>
            <div class="icon2 pointer" @click="reduce">
              <img src="../../assets/images/alone/right.png" alt="" />
            </div>
            <el-date-picker @change="changeTimeBox" :clearable="false" v-model="time" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy.MM.dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
          </div>
          <ul class="box center tooltipList">
            <li style="margin-right: 8px" class="dayColorBg pointer" v-for="(item, index) in timeList" :key="index" :class="timeActive == item.id ? 'dayColor' : ''" @click="changeTime(item.id)">
              {{ item.name }}
              <el-tooltip v-if="index == 0" class="tooltip" effect="dark" content="只展示最近5万条数据" placement="bottom">
                <div></div>
              </el-tooltip>
            </li>
          </ul>
        </div>
        <!-- <el-select v-model="typeActive" placeholder="请选择" @change="changeType(item)">
                <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>
            </el-select> -->
      </div>
      <!-- 图表 -->
      <div class="flex1 tu" v-if="!isShowEmpty">
        <div class="box center" style="width: 100%; height: 100%">
          <div class="unitDiv">
            <div class="d1">单位：{{ unit }}</div>
          </div>
          <div class="flex1" style="width: 100%; height: 100%">
            <div class="box justify">
              <div class="fs16 box center" style="width: 200px;" :class="{ 'title-align': timeActive == 0 }">
                <img style="margin-right: 12px;" src="@/assets/images/fragment-title-icon.png" />
                {{ name }}-过程线图
              </div>
              <div v-if="timeActive != 0">
                <div class="box" style="magin-bottom: 7px">
                  <div class="box center mR20">
                    <div class="fs14 mR10 charts-leage-title">最大值</div>
                    <div class="fs16 charts-leage-value">{{ arrList.max }}</div>
                  </div>
                  <div class="box center mR20">
                    <div class="fs14 mR10 charts-leage-title">平均值</div>
                    <div class="fs16 charts-leage-value">{{ arrList.avg }}</div>
                  </div>
                  <div class="box center mR20">
                    <div class="fs14 mR10 charts-leage-title">方差</div>
                    <div class="fs16 charts-leage-value">{{ arrList.diff }}</div>
                  </div>
                  <div class="box center mR20">
                    <div class="fs14 mR10 charts-leage-title">最小值</div>
                    <div class="fs16 charts-leage-value">{{ arrList.min }}</div>
                  </div>
                  <div class="box center mR20">
                    <div class="fs14 mR10 charts-leage-title">标准差</div>
                    <div class="fs16 charts-leage-value">{{ arrList.number }}</div>
                  </div>
                  <div class="box center">
                    <div class="fs14 mR10 charts-leage-title">
                      {{ isSpeed ? "均方根" : "极差" }}
                    </div>
                    <div class="fs16 charts-leage-value">{{ arrList.range }}</div>
                  </div>
                </div>
              </div>
              <div style="font-size: 16px;width: 200px;"></div>

              <!-- <div class="box center">
                            <div class="box center fs12 mR24" >
                                <div class="legend1"></div>
                                <div>最大值</div>
                            </div>
                            <div class="box center fs12 mR24" >
                                <div class="legend2"></div>
                                <div>平均值</div>
                            </div>
                            <div class="box center fs12 mR24">
                                <div class="legend3"></div>
                                <div>最小值</div>
                            </div>
                        </div> -->
            </div>
            <div id="echarts" class="flex1 echarts"></div>
          </div>
        </div>
        <div class="tuBg"></div>
      </div>
      <div class="noDataMain" v-if="isShowEmpty">
        <div class="nodata-title fs16 box center" :class="{ 'title-align': timeActive == 0 }">
          <img style="margin-right: 12px;" src="@/assets/images/fragment-title-icon.png" />
          {{ name }}-过程线图
        </div>
        <div class="nodata-content">
          <img src="../../assets/images/noData.png" alt="" />
        <span>暂无数据</span>
        </div>
      </div>
      <!-- 数据展示    eight="85%"-->
      <div v-if="tabActive.includes('1') == true">
        <el-table class="" :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData" @selection-change="handleSelectChange">
          <el-table-column align="center" prop="time" label="时间"> </el-table-column>

          <el-table-column align="center" prop="time" label="传感器编号">
            <div>{{ tableName.targetCode }}</div>
          </el-table-column>

          <el-table-column align="center" label="监测指标">
            <div>
              {{ tableName.param }}
            </div>
          </el-table-column>

          <el-table-column align="center" label="处理方式">
            {{ timeActive | timeFilters }}
          </el-table-column>

          <el-table-column align="center" v-if="timeActive == 0" prop="data[0]" label="原始值"> </el-table-column>

          <el-table-column align="center" v-if="timeActive != 0" prop="data[0]" label="最大值"> </el-table-column>
          <el-table-column align="center" v-if="timeActive != 0" prop="data[1]" label="最小值"> </el-table-column>
          <el-table-column align="center" v-if="timeActive != 0" prop="data[2]" label="平均值"> </el-table-column>

          <div slot="empty">
            <p>
              <!-- <img src="@/assets/img/noWarn.png" style="width:120px"/> -->
              <span class="span">暂无信息</span>
            </p>
          </div>
        </el-table>
        <common-pagination v-show="tableData.length > 0" class="pageMain" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import qs from "qs";
import elementResizeDetectorMaker from "element-resize-detector";
import "../../assets/echarts/resize";
import CommonPagination from "../../components/Pagination";
import * as echarts from "echarts";

export default {
  name: "dataShow",
  props: ["closeShow"],
  components: {
    CommonPagination,
  },
  data() {
    return {
      showNoData:false,
      dragging: false, // 是否正在拖拽
      offsetX: 0, // 鼠标在组件内的偏移量
      offsetY: 0, // 鼠标在组件内的偏移量
      left: 0, // 组件的左边距
      top: 0, // 组件的上边距

      isShowEmpty: false,

      projectId: "", //项目id
      targetId: "", //设备id
      name: "", //监测类型

      //  二级树形
      options1: [],
      value: "", //测点
      unit: "", //单位

      tabActive: [], //tab切换
      warningData: [],
      dayActive: "3", //日期选择  默认选择近10日
      dayList: [
        { id: "1", name: "当天" },
        { id: "2", name: "本周" },
        { id: "3", name: "近10天" },
        { id: "4", name: "本月" },
      ],
      timeList: [
        { id: "0", name: "原始值" },
        { id: "1", name: "1分钟均值" },
        { id: "2", name: "10分钟均值" },
        { id: "3", name: "1小时均值" },
        { id: "4", name: "日均值" },
      ],
      timeActive: 2, //默认选择10分钟均值
      typeActive: "1",
      typeList: [
        { id: "1", name: "趋势分析" },
        { id: "2", name: "趋势分析" },
        { id: "3", name: "趋势分析" },
      ],
      time: "",
      time1: "", //存放时间戳
      time2: "",
      size: "50000", //图表最多显示多少条

      // 默认选中值
      selected: "A",
      // 数据默认字段
      defaultProps: {
        parent: "parentId", // 父级唯一标识
        value: "id", // 唯一标识
        label: "label", // 标签显示
        children: "children", // 子级
      },
      arrList: {
        number: "",
      }, //计算后的6个值

      arrData: [], //曲线总条数
      time: [],

      // 表格数据
      tableData: [],
      tableName: {},
      pageSize: 10,
      currentPage: 1,
      total: 1,
      // ---
      client: "",
      clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
      numberID: "",
      timeX: [],
      dataY: [],
      isSpeed: false, // 是否是加速度
    };
  },
  //创建
  created() {
    this.$bus.$on("getTargetId", this.getTargetId); 
      
  },
  watch: {
    closeShow(n, o) {
      if (n == 1) {
        this.$nextTick(() => {
          var day = new Date();
          var day1 = day.getTime(); //获得当前时间戳
          var day2 = day1 - 10 * 24 * 60 * 60 * 1000;

          this.time2 = day.getTime(); //当前时间戳
          this.time1 = day1 - 10 * 24 * 60 * 60 * 1000; //10个小时的时间戳

          var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
          var end = moment(day).format("YYYY-MM-DD HH:mm:ss");
          console.log(start, end);
          this.time = [];
          this.time.push(start);
          this.time.push(end);

          this.projectId = sessionStorage.getItem("projectId");
          // this.changeTime(3);
          this.dayActive = 3;
          this.treeData();
        });
      }
    },
  },

  //安装
  mounted() {
    this.projectId = sessionStorage.getItem("projectId");
    // this.timeActive = 3;
    // console.log('timeActive', this.timeActive);

    // 获取默认时间

    // this.$nextTick(()=>{
    //     var x = [1,2,4,5,6]
    //     var item = [1,3,5,25,30]
    //     var item1 = [20,8,15,12,9]
    //     var item2 = [8,4,5,20,4]
    //     this.$chart1.moreLine('echarts',x, item, item1, item2, '');
    // })

    // let erd = elementResizeDetectorMaker();
    // let that = this;
    // erd.listenTo(document.getElementById("echarts"), (element) => {
    //     let width = element.offsetWidth;
    //     let height = element.offsetHeight;
    //     console.log(width, height)
    //     that.$nextTick(() => {
    //         //使echarts尺寸重置
    //     that.$echarts.init(document.getElementById("echarts")).resize();
    //     });
    // });

    // this.treeData();  //树形二级数据
  },

  //方法
  methods: {
    startDrag(event) {
      this.dragging = true;
      this.offsetX = event.clientX - this.left;
      this.offsetY = event.clientY - this.top;

      // 添加全局事件监听器
      window.addEventListener("mousemove", this.drag);
      window.addEventListener("mouseup", this.stopDrag);
    },
    drag(event) {
      if (this.dragging) {
        this.left = event.clientX - this.offsetX;
        this.top = event.clientY - this.offsetY;
      }
    },
    stopDrag(event) {
      this.dragging = false;

      window.removeEventListener("mousemove", this.drag);
      window.removeEventListener("mouseup", this.stopDrag);

      if (event) {
        document.dispatchEvent(new MouseEvent("mouseup"));
      }
    },
    //  获取传过来的id
    getTargetId(e) {
      this.targetId = e;
      // console.log("tartgetid", this.targetId);
      this.timeActive = 2; //默认选择近10天
    },

    dong() {
      if (this.closeShow == 1) {
        this.closeShow = 0;
        setTimeout(() => {
          this.closeShow = 1;
        }, 10);
      }
      // let myChart = this.$echarts.init(document.getElementById('echarts'));
      // myChart.setOption(option)
      // window.addEventListener("resize", () => { myChart.resize();});

      // let erd = elementResizeDetectorMaker();
      // let that = this;
      // erd.listenTo(document.getElementById("echarts"), (element) => {
      //     let width = element.offsetWidth;
      //     let height = element.offsetHeight;
      //     console.log(width, height)
      //     that.$nextTick(() => {
      //         //使echarts尺寸重置
      //     that.$echarts.init(document.getElementById("echarts")).resize();
      //     });
      // });
    },

    // 关闭窗口
    close() {
      this.closeMq(); //关闭mqtt
      console.log("关闭");
      this.closeShow = 0;
      this.$emit("getAdd", "0"); // 给父组件的
      var Dom = document.getElementById("largeWin");
      Dom.style.width = "1326px";
      Dom.style.marginLeft = "-663px";
      Dom.style.left = "50%";
      this.timeActive = "2";
      this.tabActive = []; //
    },
    // 放大
    large() {
      var Dom = document.getElementById("largeWin");
      if (Dom.style.width == "100%") {
        this.$bus.$emit("largeWin", "2"); //缩小
      } else {
        this.$bus.$emit("largeWin", "1"); //缩小
      }
      setTimeout(() => {
        this.dong();
      }, 300);
    },

    // 时间左
    add() {
      if (this.tabActive.includes(3) == true) {
        this.closeMq(); //断掉mq
        this.tabActive.forEach((item, index) => {
          if (item == 3) {
            this.tabActive.splice(index, 1);
          }
        });
      }

      this.time1 = this.time1 - 24 * 60 * 60 * 1000;
      var start = moment(this.time1).format("YYYY-MM-DD HH:mm:ss");
      this.$forceUpdate();
      this.time[0] = start;
      this.time = [this.time[0], this.time[1]];
      console.log("time", this.time);
      this.dayActive = 0;
      this.selectData(); //切换历史数据与均值
    },
    // 时间右
    reduce() {
      if (this.tabActive.includes(3) == true) {
        this.closeMq(); //断掉mq
        this.tabActive.forEach((item, index) => {
          if (item == 3) {
            this.tabActive.splice(index, 1);
          }
        });
      }
      this.time2 = this.time2 + 24 * 60 * 60 * 1000;
      var end = moment(this.time2).format("YYYY-MM-DD HH:mm:ss");
      this.$forceUpdate();
      this.time[1] = end;
      this.time = [this.time[0], this.time[1]];
      this.dayActive = 0;
      this.selectData(); //切换历史数据与均值
    },
    changeTimeBox() {
      if (this.tabActive.includes(3) == true) {
        this.dayActive = 0;
        this.closeMq(); //断掉mq
        this.tabActive.forEach((item, index) => {
          if (item == 3) {
            this.tabActive.splice(index, 1);
          }
        });
      }
      console.log("time", this.time);
      this.selectData(); //切换历史数据与均值
    },

    changeDay(id) {
      if (this.tabActive.includes("3") == true) {
        this.timeActive = 2;
        this.tabActive.forEach((item, index) => {
          if (item == 3) {
            this.tabActive.splice(index, 1);
          }
        });
        this.closeMq(); //断掉mq
      }

      console.log("快捷选天", id);
      var day = new Date();

      var time2 = day.getTime(); //当前时间戳
      this.time2 = time2; //保存当前时间时间戳
      time2 = moment(time2).format("YYYY-MM-DD HH:mm:ss");
      console.log("time2", time2);

      // 获取当天
      if (id == 1) {
        var time1 = new Date(new Date().toLocaleDateString()).getTime(); //当天时间戳
        this.time1 = time1; //保存开始处时间错
        time1 = moment(time1).format("YYYY-MM-DD HH:mm:ss");
        console.log("time1", time1);
        this.time = [time1, time2];
      }
      // 本周
      if (id == 2) {
        var nowTemp = new Date(); //当前时间
        var oneDayLong = 246060 * 1000; //一天的毫秒数
        var c_time = nowTemp.getTime(); //当前时间的毫秒时间
        var c_day = nowTemp.getDay() || 7; //当前时间的星期几
        var m_time = c_time - (c_day - 1) * oneDayLong; //当前周一的毫秒时间
        var monday = new Date(m_time); //设置周一时间对象
        var m_year = monday.getFullYear();
        var m_month = monday.getMonth() + 1;
        var m_date = monday.getDate();
        var time = m_year + "-" + m_month + "-" + m_date + " " + "00:00:00"; //周一的年月日
        console.log("time", time);
        this.time = [time, time2];
        this.time1 = monday; //获取周一0点时间戳
      }
      // 近十天
      if (id == 3) {
        var day = new Date();
        var day1 = day.getTime(); //获得当前时间戳
        var day2 = day1 - 10 * 24 * 60 * 60 * 1000;
        this.time1 = day2;
        // this.time1 = day.getTime();                   //当前时间戳
        // this.time2 = day1 - 10*24 * 60 * 60 * 1000;  //中国标准时间
        var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
        var end = moment(day).format("YYYY-MM-DD HH:mm:ss");
        this.time = [start, end];
      }
      // 本月
      if (id == 4) {
        var data = new Date();
        data.setDate(1);
        data.setHours(0);
        data.setSeconds(0);
        data.setMinutes(0);
        var time = this.formatDateTime(data.getTime());
        this.time = [time, time2];
      }

      this.dayActive = id;
      this.selectData(); //获取均值数据
    },
    formatDateTime(inputTime) {
      var date = new Date(inputTime);
      this.time1 = date;
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    // 数据列表/数据导出  888
    tab(val) {
      var open = true;
      // this.tabActive = val;
      if (this.tabActive.includes(val) == true) {
        this.tabActive.forEach((item, index) => {
          if (item == val) {
            this.tabActive.splice(index, 1);
          }
          if (item == 3) {
            this.closeMq();
            this.changeLive();
            this.data();
            open = false;
            return;
          }
        });
      } else {
        this.tabActive.push(val);
      }

      // console.log('tabActive', this.tabActive);

      if (val == 1) {
        this.getTableData();
      }
      if (val == 2) {
        this.dataExport(); //数据导出
        setTimeout(() => {
          this.tabActive.forEach((item, index) => {
            if (item == 2) {
              this.tabActive.splice(index, 1);
            }
          });
        }, 500);
      }
      if (val == 3) {
        if (open == true) {
          this.chart = echarts.init(document.getElementById("echarts"));
          this.chart.clear();
          this.dayActive = "";
          this.timeActive = "";
          this.newData(); //获取最新的500条数据

          setTimeout(() => {
            this.mqttData();
          }, 150);
        }
      }
    },
    pushArr(name, line, data, color) {
      console.log("pushArr");
      return {
        silent: true,
        lineStyle: {
          type: line,
          color: color,
          width: 1,
        },
        label: {
          formatter: name,
        },
        yAxis: data,
      };
    },
    getWarningData() {
      this.$axios
        .post(`${this.baseURL}pandect/quota/line/${this.value.id}`)
        .then((res) => {
          console.log("预警标线", res);
          this.warningData = [];
          let obj = res.data.data;
          let colorarr = ["rgba(218, 78, 78, 1)", "rgba(247, 142, 46, 0.4)", "rgba(59, 120, 195, 1)", "rgba(131, 187, 255, 0.6)"];
          let typeArr = ["solid", "solid", "solid", "solid"];
          let objarr = [];
          // "targetId": 219,
          // "duration": 1,
          // "alower": 90,
          // "blower": 80,
          // "clower": 9,
          // "dupper": 1,
          // "eupper": 2,
          // "fupper": 5,
          // "levelFirst": "1",
          // "levelSecond": "1",
          // "levelThird": ""
          if (obj["levelFirst"] == "1") {
            objarr.push(this.pushArr("重度预警", "solid", obj["alower"], colorarr[0]));
            objarr.push(this.pushArr("重度预警", "solid", obj["dupper"], colorarr[0]));
            console.log("objarr", objarr);
          }
          if (obj["levelSecond"] == "1") {
            objarr.push(this.pushArr("中度预警", "solid", obj["blower"], colorarr[1]));
            objarr.push(this.pushArr("中度预警", "solid", obj["eupper"], colorarr[1]));
            console.log("objarr", objarr);
          }
          if (obj["levelThird"] == "1") {
            objarr.push(this.pushArr("轻度预警", "solid", obj["clower"], colorarr[2]));
            objarr.push(this.pushArr("轻度预警", "solid", obj["fupper"], colorarr[2]));
            console.log("objarr", objarr);
          }

          this.warningData = objarr;

          this.selectData();
        })
        .catch((err) => {});
    },

    // 下拉选择设备  888
    changeTarget(item) {
      console.log("item", item, this.value);
      this.value = item;
      this.unit = item.unit;
      this.name = item.name;

      if (this.tabActive == 3) {
        this.closeMq();
        this.chart = echarts.init(document.getElementById("echarts"));
        this.chart.clear();
        this.newData(); //获取最新的500条数据
        setTimeout(() => {
          this.mqttData();
        }, 150);
      } else {
        // this.selectData(); //切换历史数据与均值
        this.getWarningData();
      }
    },
    // 选择类型
    changeType() {},

    // 表格
    handleSelectChange(val) {
      console.log(val);
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    // -------------------------数据请求-----------------------------------
    // 测点树形
    treeData() {
      this.unit = "";
      this.value = "";
      var id = this.projectId;
      this.$axios
        .get(`${this.baseURL}pandect/select/targertypegroup/list/two?bid=${id}`)
        .then((res) => {
          console.log("树形数据", res);
          this.options1 = res.data.data;
          // this.value = this.options1[0].list[0];

          // 判断选中的id 和单位
          this.options1.forEach((item, index) => {
            item.list.forEach((item1, index1) => {
              if (item1.id == this.targetId) {
                // console.log('item1', item1,index);
                this.value = item1;
                this.name = item1.name;
                this.unit = item1.unit;
                if (item1.typeId === 1008) {
                  this.isSpeed = true;
                } else {
                  this.isSpeed = false;
                }
                // console.log('value1', this.value,this.unit);
                // console.log('value2', this.value,this.unit);
                // this.selectData(); //切换历史数据与均值
                this.getWarningData();
              }
            });
          });
          if (this.unit == "") {
            this.kong();
          }
          // this.unit = this.options1[0].list[0].unit;
          // this.selectData();  //切换历史数据与均值
        })
        .catch((err) => {});
    },
    kong() {
      console.log("空");
      this.value = {};
      this.unit = "";
      var name = ["最大值", "均值", "最小值"];
      this.arrList = {
        max: "",
        avg: "",
        diff: "",
        min: "",
        number: "",
        range: "",
      };
      this.$chart1.moreLine("echarts", [], [], [], [], [], [], [], name, "", true);
    },

    // 数据导出
    dataExport() {
      var targetId = this.value.id; // 传感器id

      var data = {
        targetId: targetId,
        startTime: this.time[0],
        endTime: this.time[1],
        type: this.timeActive,
        sort: 1,
        size: this.size,
      };

      if (this.timeActive == 0) {
        this.$axios
          .get(`${this.baseURL}pandect/original/excel/${targetId}?sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`, { responseType: "blob" })
          .then((res) => {
            console.log("数据导出历史数据", res);

            let a = document.createElement("a");
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let objectUrl = URL.createObjectURL(blob);
            a.setAttribute("href", objectUrl);
            a.setAttribute("download", "数据导出.xlsx");
            a.click();
          })
          .catch((err) => {});
      } else {
        this.$axios
          .post(`${this.baseURL}pandect/select/target/data/excel`, qs.stringify(data), { responseType: "blob" })
          .then((res) => {
            console.log("数据导出均值", res);

            let a = document.createElement("a");
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let objectUrl = URL.createObjectURL(blob);
            a.setAttribute("href", objectUrl);
            a.setAttribute("download", "数据导出.xlsx");
            a.click();
          })
          .catch((err) => {});
      }

      // this.$axios.get(`${this.baseURL}pandect/original/excel/${targetId}?sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`, {'responseType': 'blob'}).then(res => {
      //     console.log('数据导出', res);

      //     let a = document.createElement('a');
      //     let blob = new Blob([res.data], {type: "application/vnd.ms-excel"});
      //     let objectUrl = URL.createObjectURL(blob);
      //     a.setAttribute("href",objectUrl);
      //     a.setAttribute("download", '数据导出.xlsx');
      //     a.click();
      // }).catch(err=>{
      // })
    },

    //  均值选择 timeId == 选择的id
    changeTime(timeId) {
      // console.log('timeId---------------', timeId )
      if (this.tabActive.includes("3") == true) {
        this.dayActive = 3;
        this.tabActive.forEach((item, index) => {
          if (item == 3) {
            this.tabActive.splice(index, 1);
          }
        });
        this.closeMq(); // 断掉mq
      }
      if (timeId == 0) {
        // var time2 = new Date().getTime(); //当前时间戳
        // this.time2 = time2; //保存当前时间时间戳
        // time2 = moment(time2).format("YYYY-MM-DD HH:mm:ss");
        // var time1 = new Date(new Date().getTime() - 1 * 60 * 60 * 1000);
        // time1 = moment(time1).format("YYYY-MM-DD HH:mm:ss");
        // this.time = [time1, time2];
        // // this.dayActive = -1
        // console.log(this.time)
      }
      this.timeActive = timeId; //均值的时间id
      this.selectData();
    },

    // 切换历史数据与均值
    selectData() {
      if (this.timeActive == 0) {
        this.data1(); //原始数据
      } else {
        this.data(); //均值数据
      }
      this.currentPage = 1;
      this.getTableData(); //表格数据
    },

    //  均值数据请求
    data() {
      if (this.unit == "") {
        console.log("没有单位");
        this.kong();
        return;
      }
      console.log("this.timeActive;-*-----------------", this.timeActive);
      // console.log("time", this.time);
      // console.log("1", this.value, this.unit);

      var target = this.value.id; //设备id
      // var target = this.targetId;    //传过来的id值
      var timeId = this.timeActive;
      // var sort = 0;   //排序(0:正序，1:倒序)
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在读取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)",
        target: document.querySelector(".tuBg"), //loading需要覆盖的DOM节点，默认为body
      });

      var path = `data/history/raw/average/${target}?interval=${timeId}&sort=0&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("历史均值11111", res, "this.warningData", this.warningData);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          var arr = res.data.data;
          this.arrList.number = arr.number;
          this.arrList.min = arr.min;
          this.arrList.avg = arr.avg;
          this.arrList.max = arr.max;
          this.arrList.range = arr.range;
          this.arrList.diff = arr.diff;

          console.log("arrList", this.arrList);
          var data = res.data.data.data;
          if(data && data.length){
            this.isShowEmpty=false
          }else{
            this.isShowEmpty=true
          }
          var time = [];
          var max = [];
          var min = [];
          var average = [];
          var standard = [];
          var variance = [];
          var range = [];

          data.forEach((item, index) => {
            time.push(item.time);
            max.push(item.data[0]);
            min.push(item.data[1]);
            average.push(item.data[2]);
            standard.push(item.data[3]);
            variance.push(item.data[4]);
            range.push(item.data[5]);
          });

          var name = ["最大值", "均值", "最小值"];

          if (average.length == 0) {
            var showed = true;
          } else {
            var showed = false;
          }
          this.loadingPage.close();
          setTimeout(() => {
            // console.log('warningData', this.warningData)
            if (this.warningData.length != 0) {
              if (this.warningData[0].yAxis == null) {
                this.warningData = [];
              }
            }
            document.getElementById("echarts").removeAttribute("_echarts_instance_");
            // this.$chart1.clear();
            this.$chart1.moreLine("echarts", time, max, min, average, standard, variance, range, name, "", showed, this.warningData);
            // this.$chart1.moreLineDemo('echarts',time, max, min, average, standard, variance, range, name, '', showed);
          }, 50);
        })
        .catch((err) => {});
    },

    //  历史数据请求
    data1() {
      if (this.unit == "") {
        this.kong();
        return;
      }
      console.log("time", this.time);
      // var target = this.value;   //设备id
      var target = this.value.id; //设备id
      // var target = this.targetId;
      var timeId = this.timeActive;
      var sort = 0; //排序(0:正序，1:倒序)
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在读取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)",
        target: document.querySelector(".tu"), //loading需要覆盖的DOM节点，默认为body tu tuBg
      });

      var path = `data/history/raw/${target}?sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}&reverse=1`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("历史数据", res, "this.warningData", this.warningData);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          this.arrList.number = "";
          this.arrList.min = "";
          this.arrList.avg = "";
          this.arrList.max = "";
          this.arrList.range = "";
          this.arrList.diff = "";

          console.log("arrList", this.arrList);

          var data = res.data.data;
          var timeX = [];
          var dataY = [];

          console.log("data", data);

          data.forEach((item, index) => {
            timeX.push(moment(item.time).format("YYYY-MM-DD HH:mm:ss"));
            dataY.push(item.data[0]);
          });
          if (dataY.length == 0) {
            var showed = true;
          } else {
            var showed = false;
          }
          if (data && data.length) {
            this.isShowEmpty = false;
          } else {
            this.isShowEmpty = true;
          }
          this.loadingPage.close();
          var name = ["原始值"];
          this.$chart1.line("echarts", timeX, dataY, name, "", showed, this.warningData);
        })
        .catch((err) => {});
    },

    //  均值选择 多条
    changeTimeList(selectId, id) {
      this.timeActive = selectId;
      var target = id; //设备id
      // var sort = 0;   //排序(0:正序，1:倒序)
      // this.loadingPage = this.$loading({
      //         lock: true,
      //         text: '正在上传...',
      //         spinner: 'el-icon-loading',
      //         background: 'rgba(0, 0, 0, 0.2)',
      //         target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      // });

      var path = `data/history/raw/average/${target}?interval=${selectId}&sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("历史均值", id, res);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          var arr = res.data.data;
          this.arrList.number = arr.number;
          this.arrList.min = arr.min;
          this.arrList.avg = arr.avg;
          this.arrList.max = arr.max;
          this.arrList.range = arr.range;
          this.arrList.diff = arr.diff;

          var data = res.data.data.data;
          var time = [];
          var max = [];
          var min = [];
          var average = [];
          var standard = [];
          var variance = [];
          var range = [];

          data.forEach((item, index) => {
            time.push(item.time);
            max.push(item.data[0]);
            min.push(item.data[1]);
            average.push(item.data[2]);
            standard.push(item.data[3]);
            variance.push(item.data[4]);
            range.push(item.data[5]);
          });
          if (id == 187) {
            this.time = time;
          }

          var arrData = [];
          arrData.push(max);
          arrData.push(min);
          arrData.push(average);
          // arrData.push(standard)
          // arrData.push(variance)
          // arrData.push(range)
          var name = ["最大值", "最小值", "均值"];
          var color = ["#2772F0", "#80C0FF", "#F7B500"];

          arrData.forEach((item, index) => {
            var templete = {
              data: item,
              name: name[index],
              type: "line",
              showSymbol: false, //是否显示原点
              smooth: true, //开启光滑
              itemStyle: {
                normal: {
                  color: color[index], // 小圆点颜色
                  lineStyle: {
                    width: 3,
                    type: "solid", //'dotted'虚线 'solid'实线
                    color: color[index], //线条颜色
                  },
                },
              },
            };
            this.arrData.push(templete);
          });
          console.log("arrData", this.arrData);

          // return;
          setTimeout(() => {
            this.$chart1.moreLine1("echarts", this.time, this.arrData, "");
          }, 500);
          // this.$chart1.moreLine('echarts',time, max, min, average, standard, variance, range,'');
          // this.loadingPage.close();
        })
        .catch((err) => {});
    },

    // 多条曲线选择
    changeLine(id) {
      this.arrData = []; //清空所有图表
      var arr = ["187", "216"];
      arr.forEach((item, index) => {
        this.changeTimeList(id, item);
        // this.$chart1.moreLine1('echarts',this.time,this.arrData,'');
      });
    },
    targetListData() {
      var arr = ["187", "216"];
      arr.forEach((item, index) => {});
    },

    // 表格
    getTableData() {
      if (this.unit == "") {
        return;
      }
      var id = this.projectId;
      var target = this.value.id;
      // var target = this.targetId;
      console.log("timeActive", this.timeActive);

      if (this.timeActive == 0) {
        //原始数据表格
        var path = `data/history/page/raw/${target}?interval=${this.timeActive}&sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&current=${this.currentPage}&size=10`;
      } else {
        //均值数据表格
        var path = `data/history/page/raw/average/${target}?interval=${this.timeActive}&sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&current=${this.currentPage}&size=10`;
      }

      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("表格数据", res);
          this.tableData = res.data.data.datas;
          console.log("table", this.tableData);
          this.tableName = {
            targetCode: res.data.data.targetCode,
            param: res.data.data.param[0],
            current: res.data.data.current,
          };
          this.total = res.data.data.total;
        })
        .catch((err) => {});
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getTableData();
    },

    // 获取历史数据
    newData() {
      this.timeX = [];
      this.dataY = [];

      var target = this.value.id; //设备id
      // var target = this.targetId;
      var timeId = this.timeActive;
      var sort = 0; //排序(0:正序，1:倒序)
      // this.loadingPage = this.$loading({
      //   lock: true,
      //   text: "正在读取...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.2)",
      //   target: document.querySelector(".tu") //loading需要覆盖的DOM节点，默认为body tu tuBg
      // });

      var path = `data/history/raw/${target}?sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=100&reverse=1`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("历史数据", res);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          this.arrList.number = "";
          this.arrList.min = "";
          this.arrList.avg = "";
          this.arrList.max = "";
          this.arrList.range = "";
          this.arrList.diff = "";

          console.log("arrList", this.arrList);

          var data = res.data.data;
          var timeX = [];
          var dataY = [];

          console.log("data", data);

          data.forEach((item, index) => {
            timeX.push(item.time);
            dataY.push(item.data[0]);
          });
          if (timeX.length && dataY.length) {
            this.isShowEmpty = false;
          } else {
            this.isShowEmpty = true;
          }
          // 888
          this.timeX = timeX;
          this.dataY = dataY;
         
          if (dataY.length == 0) {
            var showed = true;
          } else {
            var showed = false;
          }

          var name = ["原始值"];
          this.$chart1.line_live("echarts", timeX, dataY, name, "", showed);
          // this.loadingPage.close();
        })
        .catch((err) => {});
    },

    // 实时数据 //888
    mqttData() {
      var id = this.value.id;
      // console.log('id------', id);

      // console.log('id0',id)
      // let client = new Paho.MQTT.Client("114.116.13.1", 8083, this.clientId +0);
      // console.log('baseMQTT', this.baseMQTT);
      var path = this.topic + "/" + this.projectId + "/" + id;
      // var path = this.topic + "/10008/187"
      let client = new Paho.MQTT.Client(this.baseMQTT, this.baseNumber, this.clientId);
      let that = this;
      that.client = client;
      that.numberID = path; //动态 id 值  typeId this.projectId
      console.log("that.numberID", that.numberID);

      this.fn(client, that, that.numberID, id);
      // console.log("client", client);
      // client.reconnect(true);
    },

    fn(client, that, topic, id) {
      console.log("client", client);
      console.log("topic", topic);
      console.log("id", id);

      client.onConnectionLost = onConnectionLost;
      client.onMessageArrived = onMessageArrived;
      // 链接
      client.connect({
        onSuccess: onConnect,
        userName: that.mqttName,
        password: that.mqttPass,
      });

      function onConnect() {
        //连接成功
        console.log("连接成功");
        client.subscribe(topic);
      }

      function onConnectionLost(responseObject) {
        if (responseObject.errorCode !== 0) {
          // console.log("onConnectionLost:" + responseObject.errorMessage);
          console.log("未知原因断开", id);
          // that.x1 = [];
          // that.y1 = [];
          // that.$chart1.line1('my_echarts0', that.x1, that.y1, that.unit0);
        }
      }

      // 接收到数据
      function onMessageArrived(message) {
        // console.log('message', message);
        let res = JSON.parse(message.payloadString);
        var res1 = res.data;
        console.log("res1--------------------", res);
        // console.log('6598----------', res.pickTime);
        console.log("111", res.data);

        for (var i = 0; i < res1.length; i++) {
          res1[i].time = res1[i].pickTime;
          res1[i].value = res1[i].value;
        }
        // console.log('111', that.timeX);
        // console.log('222', that.dataY);

        var u1 = [];
        var u2 = [];
        if (that.timeX.length > 10000) {
          //888
          that.timeX.splice(0, 50);
          that.dataY.splice(0, 50);
        }
        that.$chart1.line_live("echarts", that.timeX, that.dataY, ["原始值"], "", false);

        for (var u = 0; u < res1.length; u++) {
          u1.push(that.getDate2(res1[u].time));
          u2.push(res1[u].value.toFixed(3));
        }

        that.timeX = that.timeX.concat(u1);
        that.dataY = that.dataY.concat(u2);

        that.$chart1.line_live("echarts", that.timeX, that.dataY, ["原始值"], "", false);
      }
    },
    getDate2(str) {
      let year = str.slice(0, 4);
      let month = str.slice(4, 6);
      let day = str.slice(6, 8);
      let hh = str.slice(8, 10);
      let mm = str.slice(10, 12);
      let ss = str.slice(12, 14);
      return year + "-" + month + "-" + day + " " + hh + ":" + mm + ":" + ss;
    },
    closeMq() {
      if (this.client != "") {
        this.client.unsubscribe(this.numberID);
      }
    },

    changeLive() {
      this.timeActive = 2;
      this.dayActive = 3;
    },
  },
};
</script>
<style scoped lang="scss">
.title-align {
  width: 100%;
  text-align: center;
  margin-bottom: 7px;

}

.dataShow {
  width: 100%;
  height: 100%;
  position: absolute;
}
.right-action {
  display: flex;
  align-items: center;
  img:nth-child(1) {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
  img:nth-child(2) {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}
// 默认tab切换
.tab_new {
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  width: 96px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #2667db;
}

// 选中tab
.tabColor {
  font-size: 14px;
  width: 96px;
  height: 32px;
  background: #2667db;
  border-radius: 16px;
  border: 1px solid #233361;
}
.tooltipList {
  li:nth-child(1) {
    margin-right: 37px !important;
  }
}
.dayColorBg {
  // width: 80px;
  padding-left: 22px;
  padding-right: 22px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #7387c6;
  background: #233361;
  border-radius: 15px;
  letter-spacing: -1px;
  position: relative;
  .tooltip {
    position: absolute;
    right: -21px;
    width: 14px;
    height: 14px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../../assets/images/tipoff.png");
    background-size: 100% 100%;
    &:hover {
      background-image: url("../../assets/images/tipon.png");
    }
  }
}

.dayColor {
  color: #cedaff !important;
  background: #0050b4 !important;
}

::v-deep .newUi {
}

::v-deep .new_ui_select {
  .el-select {
    width: 240px;
    height: 32px !important;

    .el-input {
      height: 32px !important;

      .el-input__inner {
        border: 1px solid #233361 !important;
        border-radius: 16px !important;
      }

      .el-input__icon {
        line-height: 17px;
      }
    }
  }
}

// 图表样式
.tu {
  width: 100%;
  // height: 100%;
  position: relative;
  padding-top: 16px;
  // padding-left: 15px;
  // padding-right: 15px;
}

.tuBg {
  position: absolute;
  opacity: 0.05;
  background: #FFFFFF;
  width: 100%;
  height: 340px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.legend1 {
  width: 24px;
  height: 3px;
  background: #2772f0;
  border-radius: 2px;
}

.legend2 {
  width: 24px;
  height: 3px;
  background: #f7b500;
  border-radius: 2px;
}

.legend3 {
  width: 24px;
  height: 3px;
  background: #80c0ff;
  border-radius: 2px;
}

#echarts {
  width: 100%;
  height: 320px;
  z-index: 2;
  position: relative;
  top: -26px;
}

.close {
  position: absolute;
  right: 0;
  top: -30px;
  right: 0px;
}

.a {
  z-index: 99;
}

.b {
  z-index: -1;
}

//单位竖
.unitDiv {
  width: 22px;

  // position: relative;
  .d1 {
    transform: rotate(-90deg) translate(-20%, -50%);
    transform-origin: 0% 0%;
    position: absolute;
    left: 40px;
    bottom: 50%;
    // top: 50%;
    // left: 0;
    width: 150px;
  }
}

.width50 {
  width: 50px;
}

.width60 {
  width: 100px;
}

.bottom {
  overflow-y: auto;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
.datatitle {
  background-image: linear-gradient(92deg, #002F83 0%, #021a4efc 100%);
  padding: 0 20px;
}
.noDataMain{
  padding-top: 0;
  height: 300px;
  background: #102248;
  align-items: flex-start;
    .nodata-title{
      color: #fff;
      margin-top: 16px;
      margin-left: 22px;

      img{
        width: 14px;
        height: 14px;
      }
    } 
    .nodata-content{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 80px;
    }
}
.charts-leage-title{
color: #BDD7FF;
font-size: 14px;
font-weight: 400;
}

.charts-leage-value{
  color: #FFFFFF;font-size: 16px;font-weight: 500;
}
</style>
